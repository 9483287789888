/* eslint-disable import/no-anonymous-default-export */
export default {
  Application: [
    'id',
    'applicationType',
    'applicantName',
    'mobileNumber',
    'applicationCompleteStep',
    'applicationNumber',
    'applicationStatus',
    'companyUEN',
    'mibCompanyName',
    'mibCompanyUEN',
    'positionInCompany',
    'amountRequested',
    'durationInMonths',
    'dataViaSingpass',
    'progress',
    'experianConsent',
    'interestRate',
    'emi',
    'partnerId',
    'customerId',
    'bankStatementsReportFileName',
    'bankStatementsReportFilePath',
    'bankStatementsXlsxReportFileName',
    'bankStatementsXlsxReportFilePath',
    'envelopeId',
    'credilinqEnvironment',
    'perfiosIntegration',
    'bankName',
    'bankAccountHolder',
    'bankAccountNumber',
    'bankSwiftCode',
  ],
  LoanEligibility: [
    'id',
    'loanPurpose',
    'companyName',
    'dateOfIncorporation',
    'industryType',
    'otherType',
    'businessMobileNumber',
    'businessEmailAddress',
    'turnover',
    'profit',
    'anyOutstandingDebts',
    'totalMonthlyInstallmentsToBanks',
    'totalMonthlyInstallmentsToNonBanks',
    'totalRevolvingCreditOutstandingToBanks',
    'totalRevolvingCreditOutstandingToNonBanks',
    'currentLitigationAmount',
  ],
  IncomeDocuments: ['id', 'type'],
  DocumentFile: ['filename', 'filesize', 'ref', 'url'],
  User: ['id', 'name', 'email', 'role'],
  ExternalDataStore: ['id', 'type', 'data'],
  PhoneDisscussion: ['id', 'applicantName', 'applicantNumber', 'dateOfDiscussion'],
  ShareholderKYCStatus: [
    'id',
    'dataViaSingpass',
    'isDecisionMaker',
    'isGuarantor',
    'isDirector',
    'isCompanyDirector',
    'idNumber',
    'personName',
    'nationality',
    'corppasEmail',
    'corppasMobileNumber',
    'uen',
    'entityName',
    'category',
    'shareType',
    'allocation',
    'currency',
    'kycStatus',
    'shareholderType',
  ],
}
