/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ServerError, useLazyQuery, useMutation } from '@apollo/client'

import { Step1Sub1 } from './Step1Sub1'
import { Step1Sub2 } from './Step1Sub2'
import { Step1Sub3 } from './Step1Sub3'
import Alert from '../../../../_metronic/partials/alert'
import mutations from './../../../../setup/graphql/mutations'
import { fetchStop } from '../../../../redux/actions/common'
import { setCoApplicant, setOpenVerification, setPersonData, setSubStep } from '../redux/actions'
import query from '../../../../setup/graphql/query'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { Spinner } from 'react-bootstrap-v5'

const Step1: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()
  const { id } = params

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [mobileNo, setMobileNo] = useState('')
  const [showOtp, setShowOtp] = useState(false)

  const subStep = useSelector((state: any) => state.coApplicant.subStep)
  const co_applicant = useSelector((state: any) => state.coApplicant.co_applicant)
  const subStep1 = useSelector((state: any) => {})
  const [sendOTP, onSuccessSendOTP] = useMutation(mutations.SEND_OTP)
  const [validateOTP, onSucccessValidateOTP] = useMutation(mutations.VALIDATE_OTP)
  const location = useLocation()
  const searchParam: any[] = location.pathname.split('/')
  // const searchParamsData: string | null = searchParam[searchParam.length - 1] === "co-applicant" || searchParam[searchParam.length - 1] === "" ? null : searchParam[searchParam.length - 1]
  const [getCoApplicant, onSuccessGetCoApplicant] = useLazyQuery(query.GET_SHAREHOLDER_DETAILS)

  useEffect(() => {
    if (onSuccessSendOTP.called && !onSuccessSendOTP.loading) {
      dispatch(fetchStop())
      if (onSuccessSendOTP.data) {
        if (onSuccessSendOTP.data.generateOtp.success) {
          localStorage.removeItem('coApplicationId')
          localStorage.removeItem('singpass')
          history.push('/co-applicant/' + onSuccessSendOTP.data.generateOtp.id)
          dispatch(setSubStep(2))
          setShowOtp(true)
          dispatch(setOpenVerification(false))
          onSuccessSendOTP.reset()
        } else {
          setShowAlert(true)
          setAlertMsg(
            'eKYC is already verified using the link or the input mobile number does not match. Please verify and try again.'
          )
          setAlertType('primary')
          dispatch(fetchStop())
        }
      }

      if (onSuccessSendOTP.error) {
        const { networkError } = onSuccessSendOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessSendOTP.loading,
    onSuccessSendOTP.data,
    onSuccessSendOTP.error,
    onSuccessSendOTP.called,
  ])
  useEffect(() => {
    if (co_applicant == null) {
      getCoApplicant({ variables: { shareholderId: id } })
      dispatch(setOpenVerification(false))
    }
  }, [])

  useEffect(() => {
    if (onSucccessValidateOTP.called && !onSucccessValidateOTP.loading) {
      dispatch(fetchStop())
      if (onSucccessValidateOTP.data) {
        if (onSucccessValidateOTP.data.validateOtp.success) {
          if (co_applicant == null) {
            getCoApplicant({ variables: { data: { id } } })
          } else {
            setShowOtp(false)
            dispatch(setOpenVerification(true))
          }

          onSucccessValidateOTP.reset()
        } else {
          const message = onSucccessValidateOTP.data.validateOtp.message
          setShowAlert(true)
          setAlertMsg(message)
          setAlertType('primary')
        }
      }

      if (onSucccessValidateOTP.error) {
        const { networkError } = onSucccessValidateOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSucccessValidateOTP.loading,
    onSucccessValidateOTP.data,
    onSucccessValidateOTP.error,
    onSucccessValidateOTP.called,
  ])

  useEffect(() => {
    if (onSuccessGetCoApplicant.data && !onSuccessGetCoApplicant.error) {
      dispatch(fetchStop())
      if (subStep != 3) dispatch(setOpenVerification(true))
      dispatch(setCoApplicant(onSuccessGetCoApplicant.data.getShareHolderById))
      if (['stage', 'sandbox', 'dev'].includes(localStorage.getItem('env') as string)) {
        ;(async () => {
          if (
            onSuccessGetCoApplicant.data.getShareHolderById &&
            onSuccessGetCoApplicant.data.getShareHolderById.idNumber
          ) {
            const response = await import(
              `../../../../utils/custom/202101103R/${onSuccessGetCoApplicant?.data?.getShareHolderById?.idNumber}.json`
            )
            const eligibilityData: any = response.default
            if (eligibilityData) {
              // Dispatch the action to update the loan eligibility in the Redux store
              dispatch(setPersonData(eligibilityData))
            }
          }
        })()
      }
    }
  }, [onSuccessGetCoApplicant.data, onSuccessGetCoApplicant.error, onSuccessGetCoApplicant.loading])

  const handleCloseOtp = () => {
    setShowOtp(false)
    dispatch(setSubStep(1))
  }
  return (
    <>
      {subStep === 1 && onSuccessGetCoApplicant?.data?.getShareHolderById && (
        <Step1Sub1
          sendOTP={sendOTP}
          setMobile={setMobileNo}
          id={id}
          name={onSuccessGetCoApplicant?.data?.getShareHolderById?.personName}
          mobile={onSuccessGetCoApplicant?.data?.getShareHolderById?.corppasMobileNumber}
        />
      )}
      {subStep === 2 && (
        <>
          <Step1Sub2
            mobileNo={mobileNo}
            sendOTP={sendOTP}
            validateOTP={validateOTP}
            id={id}
            show={showOtp}
            handleClose={handleCloseOtp}
          />
          {/* <Step1Sub1
            sendOTP={sendOTP}
            setMobile={setMobileNo}
            id={id}
            name={onSuccessGetCoApplicant?.data?.getShareHolderById?.personName}
            mobile={onSuccessGetCoApplicant?.data?.getShareHolderById?.corppasMobileNumber}
          /> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              minHeight: '100vh',
              background: 'rgba(255,255,255,0.3)',
              zIndex: 99,
              position: 'fixed',
              width: '100%',
              left: 0,
              top: 0,
            }}
          >
            <Spinner
              style={{
                height: '4rem',
                width: '4rem',
              }}
              animation='grow'
              variant='danger'
            />
          </div>
        </>
      )}
      {subStep === 3 && <Step1Sub3 getCoApplicant={getCoApplicant} />}
      {/* {subStep === 4 && <Step1Sub4 {...additionalProps} />} */}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </>
  )
}

export { Step1 }
