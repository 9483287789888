import { ServerError, useMutation } from '@apollo/client'
import axios from 'axios'
import { useFormik } from 'formik'
import { FC, Fragment, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PhoneInput from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CustomLoaderModal } from '../../../../_metronic/helpers/components/CustomLoaderModal'
import Alert from '../../../../_metronic/partials/alert'
import { fetchStartWithForward, fetchStop } from '../../../../redux/actions/common'
import { getIntegrationURLFromENV } from '../../../../utils/getIntegrationURLFromENV'
import mutations from './../../../../setup/graphql/mutations'
import { AlexiRoutes } from '../../../../utils/constants/AlexiRoutes.enum'
import { EmailIcon } from '../../../../svg/EmailIcon'
import { MobileIcon } from '../../../../svg/MobileIcon'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'
import { IBANOrAccountNumberRegex } from '../../../../utils/constants/regex'

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// const dateObj = (d: any): any => {
//   return {
//     year: Number(d.getFullYear()),
//     month: Number(d.getMonth() + 1),
//     day: Number(d.getDate()),
//     hour: Number(d.getHours()),
//     minute: Number(d.getMinutes()),
//   }
// }

const loanType = localStorage.getItem('loanType')

const Step4: FC = ({
  // prevStep,
  // nextStep,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [lastPickedDateError, setLastPickedDateError] = useState<boolean>(false)
  const guarantor = useSelector((state: any) => state.kycApplication.guarantor)
  const decisionMaker = useSelector((state: any) => state.kycApplication.decisionMaker)
  const directors = useSelector((state: any) => state.kycApplication.directors)
  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const loanEligibility = useSelector((state: any) => state.kycApplication.loanEligibility)
  const singPassInfo = useSelector((state: any) => state.kycApplication.singPass)
  // const [bankStatement, setBankStatement] = useState<any>([])
  // const [incorrectFileType, setIncorrectFileType] = useState<boolean>(false)
  const [createPhoneDiscussion, onSuccessCreatePhoneDiscussion] = useMutation(
    mutations.CREATE_PHONE_DISSCUSSION
  )
  const [resendGuarantorEmail, onSuccessResendGuarantorEmail] = useMutation(
    mutations.RESEND_GUARANTOR_EMAIL
  )

  const { phoneDiscussion, applicationStatus } = loanApplication

  // dateOfDiscussion: phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,

  useEffect(() => {
    if (onSuccessCreatePhoneDiscussion.called && !onSuccessCreatePhoneDiscussion.loading) {
      // dispatch(fetchStop())
      if (onSuccessCreatePhoneDiscussion.data) {
        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 5,
              applicationStatus: 'submitted',
              bankName: formik?.values?.bankName,
              bankAccountHolder: formik?.values?.bankAccountHolder,
              bankAccountNumber: formik?.values?.bankAccountNo,
              bankSwiftCode: formik?.values?.bankSwiftCode,
            },
            where: { id: loanApplication.id },
          },
        })
        setTimeout(() => {
          history.push(`/${AlexiRoutes.SG_ONBOARDING}/completed/${loanApplication?.id}`)
        }, 1000)
      } else {
      }

      if (onSuccessCreatePhoneDiscussion.error) {
        const { networkError } = onSuccessCreatePhoneDiscussion.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessCreatePhoneDiscussion.loading])

  useEffect(() => {
    if (onSuccessResendGuarantorEmail.called && !onSuccessResendGuarantorEmail.loading) {
      dispatch(fetchStop())
      if (onSuccessResendGuarantorEmail.data) {
        if (onSuccessResendGuarantorEmail.data.resendGuarantorEmail) {
          setShowAlert(true)
          setAlertType('success')
          setAlertMsg('Email sent successfully')
        } else {
          setShowAlert(true)
          setAlertType('primary')
          setAlertMsg('Email could not be sent')
        }
        onSuccessResendGuarantorEmail.reset()
      }

      if (onSuccessResendGuarantorEmail.error) {
        const { networkError } = onSuccessResendGuarantorEmail.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        dispatch(fetchStop())
      }
    }
  }, [onSuccessResendGuarantorEmail.loading])

  const initialValues = {
    bankName: '',
    bankAccountHolder: '',
    bankAccountNo: '',
    retypeBankAccountNo: '',
    bankSwiftCode: '',
    // bankStatement: '',
    applicantName:
      phoneDiscussion.length > 0 ? phoneDiscussion[0].applicantName : decisionMaker?.personName,
    applicantNumber:
      phoneDiscussion.length > 0
        ? phoneDiscussion[0].applicantNumber
        : decisionMaker?.corppasMobileNumber,
    dateOfDiscussion:
      phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,
    confirmCheckbox: false,
  }

  const validationSchema = Yup.object().shape({
    bankName: Yup.string()
      .required('Bank Name required')
      .matches(/^(?:(?=^.*([a-z]|[A-Z]))(([a-z]|[A-Z]))|([a-z]|[A-Z]))/g, {
        message: 'Please enter valid Bank Name',
      }),
    bankAccountHolder: Yup.string()
      .required('Bank Account Holder required')
      .matches(/^(?:(?=^.*([a-z]|[A-Z]))(([a-z]|[A-Z]))|([a-z]|[A-Z]))/g, {
        message: 'Please enter valid Bank Account Holder',
      }),
    bankAccountNo: Yup.string()
      .required('Bank Account Number required')
      .matches(IBANOrAccountNumberRegex, 'Enter Valid Bank Account Number'),
    retypeBankAccountNo: Yup.string()
      .required('Retype Account No required')
      .matches(IBANOrAccountNumberRegex, 'Enter Valid Bank Account Number')
      .test('account-no-match', 'Account Numbers do not match', function (value) {
        return value === this.parent.bankAccountNo
      }),
    bankSwiftCode: Yup.string()
      .required('Bank Swift Code is required.')
      .test(
        'len',
        'Please enter valid swift code having 8 OR 11 characters.',
        (val) => val?.length === 8 || val?.length === 11
      )
      .matches(/^[A-Za-z0-9]+$/, {
        message: 'Please enter valid bank swift code.',
      }),
    // bankStatement: Yup.string().required('Bank Statement is requred'),
    applicantName: Yup.string().required('Applicant Name required'),
    applicantNumber: Yup.string().required('Applicant Number required'),
    dateOfDiscussion: Yup.string()
      .required('Appointment Date and Time required')
      .typeError('Appointment Date and Time required'),
    confirmCheckbox: Yup.bool().oneOf([true], 'Please check checkbox to move forward'),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (lastPickedDateError) {
        formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
        return
      }

      const { applicantName, applicantNumber, dateOfDiscussion } = values
      // dispatch(fetchStartWithForward())
      createPhoneDiscussion({
        variables: {
          data: {
            applicantName,
            applicantNumber,
            dateOfDiscussion,
            application: {
              connect: {
                id: loanApplication.id,
              },
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (lastPickedDateError) {
      formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
    }
  }, [formik.errors])

  const sendEmail = (id: string) => {
    dispatch(fetchStartWithForward())
    resendGuarantorEmail({
      variables: {
        shareholderId: id,
      },
    })
  }

  // const handleFileUpload = (event: any, type: string, fileType: string = 'application/pdf') => {
  //   event.preventDefault()

  //   if (event.target.validity.valid) {
  //     let files = []
  //     let uploadDocs = []

  //     const isValid = isValidFile(event, fileType)

  //     if (isValid) {
  //       for (let file of event.target.files) {
  //         file.filename = file.name
  //         files.push({ type: type, file })
  //         uploadDocs.push({
  //           application: {
  //             connect: {
  //               id: loanApplication.id,
  //             },
  //           },
  //           type: type,
  //           documentFile: {
  //             upload: file,
  //           },
  //         })
  //       }

  //       setIncorrectFileType(false)
  //       formik.setFieldValue('bankStatement', event?.target?.files[0])

  //       setBankStatement(uploadDocs)
  //     }
  //   }
  // }

  // const isValidFile = (event: any, type: string) => {
  //   const checkType = [...event.target.files].some((file: any) => file.type !== type)
  //   if (checkType) {
  //     setIncorrectFileType(true)
  //     return false
  //   }
  //   return true
  // }

  return (
    <div className='d-flex flex-column  flex-column-fluid'>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Shareholder Authentication Status</h2>
            <hr className='text-gray-400' />
          </div>
          <div className='sub-heading text-secondary-400 fs-4 mb-10'>
            Before submitting all shareholders mentioned below should complete their eKYC. A link
            has been sent to their email address.
          </div>
          <div className='row mb-4'>
            {guarantor &&
              guarantor.map((o: any, i: number) => {
                return (
                  <div className='col-md-6' key={i}>
                    <div className='cardNew w-100 border'>
                      <div className='d-flex gap-3 mb-5'>
                        <div className='symbol symbol-35px symbol-circle'>
                          <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>
                            {`${(o.personName.split(' ').length > 1
                              ? o.personName.split(' ')[0][0] +
                                o.personName.split(' ')[o.personName.split(' ').length - 1][0]
                              : o.personName.split(' ')[0][0]
                            ).toUpperCase()}`}
                          </span>
                        </div>

                        {/* Person's Name and Director Role */}
                        <div>
                          <div className='fs-2 text-dark'>{o.personName}</div>
                          <div className='text-muted'>
                            {' '}
                            Guarantor
                            {o.isDirector ? ', Director' : ''}
                          </div>
                        </div>

                        {/* KYC Status Badge */}
                        <div
                          className={`ms-auto badge ${
                            o.kycStatus === 'pending' ? 'badge-warning' : 'badge-success'
                          }`}
                        >
                          {o.kycStatus === 'pending' ? 'Pending' : 'Success'}
                        </div>
                      </div>

                      {/* Email and Mobile */}
                      <div
                        className='ms-13'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div>
                          <div className='kyc_review fs-5 mt-1 mb-2 d-flex align-items-center'>
                            <EmailIcon color='currentColor' fontSize={18} />
                            <span className='ms-2'>{o.corppasEmail}</span>{' '}
                          </div>

                          <div className='kyc_review fs-5 mb-3 d-flex align-items-center'>
                            <MobileIcon color='currentColor' fontSize={18} />
                            <span className='ms-2'>{o.corppasMobileNumber}</span>{' '}
                          </div>
                        </div>
                        {/* Resend Button */}
                        {o.kycStatus === 'pending' && (
                          <div
                            className='button button-secondary-filled mb-4 '
                            onClick={(e) => sendEmail(o.id)}
                          >
                            Resend <RightAeroIcon color='currentColor' />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            {directors &&
              directors.map((o: any, i: number) => {
                return (
                  !o.isGuarantor && (
                    <div className='col-md-6' key={i}>
                      <div className='cardNew w-100 border'>
                        <div className='d-flex gap-3 mb-5'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>
                              {`${(o.personName.split(' ').length > 1
                                ? o.personName.split(' ')[0][0] +
                                  o.personName.split(' ')[o.personName.split(' ').length - 1][0]
                                : o.personName.split(' ')[0][0]
                              ).toUpperCase()}`}
                            </span>
                          </div>

                          {/* Person's Name and Director Role */}
                          <div>
                            <div className='fs-2 text-dark'>{o.personName}</div>
                            <div className='text-muted'>Director</div>
                          </div>

                          {/* KYC Status Badge */}
                          <div
                            className={`ms-auto badge ${
                              o.kycStatus === 'pending' ? 'badge-warning' : 'badge-success'
                            }`}
                          >
                            {o.kycStatus === 'pending' ? 'Pending' : 'Success'}
                          </div>
                        </div>

                        {/* Email and Mobile */}
                        <div
                          className='ms-13'
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div>
                            <div className='kyc_review fs-5 mt-1 mb-2 d-flex align-items-center'>
                              <EmailIcon color='currentColor' fontSize={18} />
                              <span className='ms-2'>{o.corppasEmail}</span>{' '}
                            </div>
                            <div className='kyc_review fs-5 mb-3 d-flex align-items-center'>
                              <MobileIcon color='currentColor' fontSize={18} />
                              <span className='ms-2'>{o.corppasMobileNumber}</span>{' '}
                            </div>
                          </div>
                          {/* Resend Button */}
                          {o.kycStatus === 'pending' && (
                            <div
                              className='button button-secondary-filled mb-4 '
                              onClick={(e) => sendEmail(o.id)}
                            >
                              Resend <RightAeroIcon color='currentColor' />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              })}
          </div>
          <div>
            {guarantor.length > 0 && guarantor.some((o: any) => o.kycStatus === 'pending') && (
              <div className='d-flex flex-stack flex-grow-1'>
                <div className=''>
                  <div className='fs-6 text-gray-600'>
                    Thank you for providing us with the relevant information needed to complete the
                    company's loan application. You will be able to submit the application as soon
                    as the shareholders completes their eKYC.
                    <div className='text-black'>
                      Note: If shareholders have completed their eKYC then kindly refresh the page
                      to update the status
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Provide Bank Account</h2>
            <hr className='text-gray-400' />
          </div>
          <div className='sub-heading text-secondary-400 fs-4 mb-10'>
            Please help us with your bank account details. The same bank account will be used to
            disburse the funds upon approval.
          </div>
          <div className='row mt-10'>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Bank Name
              </label>
              <input
                name='bankName'
                type='text'
                className='form-control form-control-solid'
                value={formik.values.bankName}
                onChange={formik.handleChange}
                // disabled
              />
              {formik.touched.bankName && formik.errors.bankName && (
                <div className='text-danger mt-2'>{formik.errors.bankName}</div>
              )}
            </div>

            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Account Holder
              </label>
              <input
                name='bankAccountHolder'
                type='text'
                className='form-control form-control-solid'
                value={formik.values.bankAccountHolder}
                onChange={formik.handleChange}
                // disabled
              />
              {formik.touched.bankAccountHolder && formik.errors.bankAccountHolder && (
                <div className='text-danger mt-2'>{formik.errors.bankAccountHolder}</div>
              )}
            </div>

            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Account No
              </label>
              <input
                name='bankAccountNo'
                type='text'
                className='form-control form-control-solid'
                value={formik.values.bankAccountNo}
                onChange={formik.handleChange}
                // disabled
              />
              {formik.touched.bankAccountNo && formik.errors.bankAccountNo && (
                <div className='text-danger mt-2'>{formik.errors.bankAccountNo}</div>
              )}
            </div>

            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Retype Account No
              </label>
              <input
                name='retypeBankAccountNo'
                type='text'
                className='form-control form-control-solid'
                value={formik.values.retypeBankAccountNo}
                onChange={formik.handleChange}
                onPaste={(e) => {
                  e.preventDefault()
                  alert('cut,copy & paste options are disabled !!')
                }}
                autoComplete='off'
                // disabled
              />
              {formik.touched.retypeBankAccountNo && formik.errors.retypeBankAccountNo && (
                <div className='text-danger mt-2'>{formik.errors.retypeBankAccountNo}</div>
              )}
            </div>

            <div className='col-md-12 mb-5'>
              <label htmlFor='' className='input-field-label required'>
                Swift Code
              </label>
              <input
                name='bankSwiftCode'
                type='text'
                className='form-control form-control-solid'
                value={formik.values.bankSwiftCode}
                onChange={formik.handleChange}
                // disabled
              />
              {formik.touched.bankSwiftCode && formik.errors.bankSwiftCode && (
                <div className='text-danger mt-2'>{formik.errors.bankSwiftCode}</div>
              )}
            </div>
          </div>
        </div>

        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Phone Discussion</h2>
            <hr className='text-gray-400' />
          </div>

          <div className='row mb-10 mt-10'>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label'>
                Applicant Name
              </label>
              <div className='col-sm-12'>
                <input
                  name='applicantName'
                  type='text'
                  className='form-control form-control-solid'
                  value={formik.values.applicantName}
                  onChange={formik.handleChange}
                  // disabled
                />
                {formik.touched.applicantName && formik.errors.applicantName && (
                  <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
                )}
              </div>
            </div>
            <div className='col-md-6 mb-5'>
              <label htmlFor='' className='input-field-label'>
                Applicant Number
              </label>
              <div className='col-sm-12'>
                <PhoneInput
                  id='applicantNumber'
                  international
                  defaultCountry='SG'
                  // countryCallingCodeEditable={false}
                  value={formik.values.applicantNumber}
                  onChange={(value) => formik.setFieldValue('applicantNumber', value)}
                  placeholder='Enter phone number'
                  className='int-phone-input'
                  // readOnly
                />
                {formik.touched.applicantNumber && formik.errors.applicantNumber && (
                  <div className='text-danger mt-2'>{formik.errors.applicantNumber}</div>
                )}
              </div>
            </div>
            <div className='col-md-12 mb-5 '>
              <label htmlFor='' className='input-field-label'>
                Appointment Date/Time
              </label>
              <div className='col-sm-12'>
                <DatePicker
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode='select'
                  scrollableMonthYearDropdown
                  readOnly
                  showMonthDropdown
                  showTimeSelect
                  dateFormat={'MM/dd/yyyy, h:mm aa'}
                  locale='en'
                  required={true}
                  minDate={tomorrow}
                  selected={formik.values.dateOfDiscussion}
                  onChange={(value: any) => {
                    formik.setFieldValue('dateOfIncorporation', value)
                  }}
                />
                {formik.touched.dateOfDiscussion && formik.errors.dateOfDiscussion && (
                  <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
                )}

                <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className='mb-10 mt-10'
          style={{
            backgroundColor: 'rgba(253, 203, 110, 0.2)',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            border: '1px dashed gray',
            borderRadius: '5px',
            flexDirection: 'column',
          }}
        >
          <div style={{ fontSize: '18px', fontWeight: '700' }}>
            You are about to submit your application
          </div>
          <div style={{ fontSize: '14px', fontWeight: '500', color: '#7E8299' }}>
            By clicking this I agree that all the above information is correct and can be used for
            underwriting [Update the copy at finalization]
          </div>
        </div> */}

        {applicationStatus === 'in-progress' &&
          directors.length > 0 &&
          !directors.some((o: any) => o.kycStatus === 'pending') && (
            <>
              <div className='cardNew'>
                <div className='section-heading'>
                  <h2>Consent and Terms</h2>
                  <hr className='text-gray-400' />
                </div>
                <div className='justify-content'>
                  <p className='fs-5'>You are about to submit your application</p>
                  <p className='fs-5'>
                    <label className='form-check-label me-1 mt-2 d-inline-flex align-items-center'>
                      <input
                        name='confirmCheckbox'
                        type='checkbox'
                        className='form-check-input form-check-inline form-check-solid'
                        checked={formik.values.confirmCheckbox}
                        onChange={formik.handleChange}
                      />
                      <span className='fs-5'>
                        By clicking this I agree that all the above information is correct and can
                        be used for underwriting. Please find Terms and Conditions
                        <a
                          href={toAbsoluteUrl('/files/Standard_Customer_T&Cs.pdf')}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='ms-2'
                        >
                          Here
                        </a>
                      </span>
                    </label>
                    {formik.touched.confirmCheckbox && formik.errors.confirmCheckbox && (
                      <div className='text-danger mt-2'>{formik.errors.confirmCheckbox}</div>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}

        <div className='cardNew stickeyFooter'>
          <div className='d-flex flex-end'>
            <button
              type='submit'
              className='button  button-primary me-3'
              disabled={
                applicationStatus === 'submitted' ||
                directors.some((o: any) => o.kycStatus === 'pending')
              }
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your document for signing, it might take a while.'
      />
    </div>
  )
}

export { Step4 }
