import {
  SET_PARTNER_APPLICATION,
  SET_PARTNER_APPLICATION_STATUS,
  SET_PARTNER_STEP,
  SET_PARTNER_SUB_STEP,
  SET_COMPANY_VERIFICATION_METHOD,
  SET_ENTITY_DATA,
  SET_PERSON_DATA,
  SET_SHAREHOLDERS,
  SET_GUARANTOR,
  SET_DIRECTOR,
  SET_DECISION_MAKER,
  AUTHORIZE_ACRA,
  AUTHORIZE_ACRA_SUCCEEDED,
  FETCH_ENTITY_PERSON,
  FETCH_ENTITY_PERSON_SUCCEEDED,
  SET_SINGPASS_INFO,
  SET_PARTNER_ELIGIBILITY,
  RESET_PARTNER,
  APPLICATION_DETAILS_DELETE,
  APPLICATION_DETAILS_SAVE,
  SET_PARTNER_DETAILS,
  SET_FUND_DETAILS,
} from '../types'

export const setPartnerApplication = (payload: any) => ({
  type: SET_PARTNER_APPLICATION,
  payload,
})

export const setPartnerDetails = (payload: any) => ({
  type: SET_PARTNER_DETAILS,
  payload,
})

export const setFundDetails = (payload: any) => ({
  type: SET_FUND_DETAILS,
  payload,
})

export const setPartnerApplicationStatus = (status: number) => ({
  type: SET_PARTNER_APPLICATION_STATUS,
  status,
})

export const setPartnerStep = (step: number) => ({
  type: SET_PARTNER_STEP,
  step,
})

export const setPartnerSubStep = (step: number) => ({
  type: SET_PARTNER_SUB_STEP,
  step,
})

export const setCompanyVerificationMethod = (method: number) => ({
  type: SET_COMPANY_VERIFICATION_METHOD,
  method,
})

export const setPartnerEligibility = (payload: any) => ({
  type: SET_PARTNER_ELIGIBILITY,
  payload,
})

export const setEntityDataPartner = (payload: any) => ({
  type: SET_ENTITY_DATA,
  payload,
})

export const setPersonData = (payload: any) => ({
  type: SET_PERSON_DATA,
  payload,
})

export const setShareholders = (payload: any) => ({
  type: SET_SHAREHOLDERS,
  payload,
})

export const setGuarantor = (payload: any) => ({
  type: SET_GUARANTOR,
  payload,
})
export const setDirector = (payload: any) => ({
  type: SET_DIRECTOR,
  payload,
})

export const setDecisionMaker = (payload: any) => ({
  type: SET_DECISION_MAKER,
  payload,
})

export const setSingPassInfo = (payload: any) => ({
  type: SET_SINGPASS_INFO,
  payload,
})

export const resetPartner = () => ({
  type: RESET_PARTNER,
})

export const authorizeAcra = (payload: any) => ({
  type: AUTHORIZE_ACRA,
  payload,
})

export const authorizeAcraSucceeded = (payload: any) => ({
  type: AUTHORIZE_ACRA_SUCCEEDED,
  payload,
})

export const fetchEntityPerson = (payload: any) => ({
  type: FETCH_ENTITY_PERSON,
  payload,
})

export const fetchEntityPersonSucceeded = (payload: any) => ({
  type: FETCH_ENTITY_PERSON_SUCCEEDED,
  payload,
})

export const saveApplicationDetails = (payload: any) => ({
  type: APPLICATION_DETAILS_SAVE,
  payload,
})

export const deleteApplicationDetails = () => ({
  type: APPLICATION_DETAILS_DELETE,
})
